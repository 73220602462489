<template>
  <div>
    <c-input
      v-bind="$props"
      @blur="handleBlur"
      @focus="handleFocus"
      v-on="$listeners"
      :info="info"
      :state="state"
      :mask="mask"
      :masked="masked"
      :data-cy="dataCy"
      :placeholder="placeholder"
      :class="{ 'ui-opacity-60': disabled }"
    >
      <template
        v-if="currentIcon"
        slot="icon"
        slot-scope="iconScope"
      >
        <div @click.stop="$emit('handle-icon-click')">
          <Icon
            :name="currentIcon"
            class="ui-transition-all ui-in-out ui-duration-300 ui-z-50"
            :color="iconScope.icon.iconStyle"
            :filled="iconFilled"
          />
        </div>
      </template>
    </c-input>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';
import CInput from './c-input';

export default {
  inheritAttrs: false,
  name: 'PInput',
  components: {
    CInput,
    Icon,
  },
  data () {
    return {
      state: 'DEFAULT',
      iconError: 'error',
      iconSuccess: 'check',
      info: {},
      iconFilled: false,
      isFocused: false,
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    mask: {
      type: [String, Array, RegExp],
      default: null,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    iconInfo: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    validator: {
      type: Function,
      default: () => {
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentIcon () {
      const isPassword = this.type === 'password';
      if (!this.state || this.icon) return this.icon;
      switch (true) {
      case this.state === 'ERROR' && !isPassword :
        return this.iconError;
      case this.state === 'SUCCESS' && !isPassword :
        return this.iconSuccess;
      case this.state === 'FOCUS' && !isPassword :
        return this.icon;
      default:
        return this.icon;
      }
    },
  },
  mounted () {
    this.info = this.getDefaultInfo();
  },
  methods: {
    handleFocus () {
      this.state = 'FOCUS';
      this.info.message = this.hint;
      this.info.icon = this.iconInfo;
      this.isFocused = true;
    },
    handleBlur () {
      this.isFocused = false;
      this.validate();
    },
    setErrorState (message) {
      this.state = 'ERROR';
      this.info.message = message;
      this.info.icon = null;
      if (!this.icon) this.iconFilled = true;
    },
    setSuccessState () {
      this.state = 'SUCCESS';
      this.info.message = this.hint;
      this.info.icon = this.iconInfo;
      if (!this.icon) this.iconFilled = true;
    },
    validate ({ dryRun = false, showErrors = false } = {}) {
      const errorMessage = this.validator(this.value);
      if (errorMessage) {
        if (showErrors || !dryRun) this.setErrorState(errorMessage);
        return false;
      } else {
        if (!dryRun) this.setSuccessState();
        return true;
      }
    },
    getDefaultInfo () {
      return {
        icon: this.state === 'ERROR' ? null : this.iconInfo,
        message: this.hint,
        iconFilled: true,
      };
    },
  },
};
</script>

<style scoped>
::v-deep input[type="date"] {
  position: relative;
}

/* Torna o icone do datepicker nativo invisível e estica ele sobre o input para para que seja clicável e acione o datepicker.*/
::v-deep input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
</style>
